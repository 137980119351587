@media (max-width: 767.98px) {
  body .container-white {
    font-size: 90%;
  }
}
@media (max-width: 575.98px) {
  body .container-white {
    font-size: 80%;
  }
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}

.shell {
  width: 100%;
  background-color: #aaa;
  background-image: none;
}
@media (min-width: 1380px) {
  .shell {
    width: 1360px;
    background-image: url(../external_art/gradient_bg.jpg);
  }
}
.shell .container {
  width: 90%;
}
@media (min-width: 1380px) {
  .shell .container {
    width: 960px;
  }
}

@media (max-width: 767.98px) {
  .top h2 {
    font-size: 90%;
  }
}
.top img {
  display: inline;
}

.header img {
  width: 100%;
}

@media (max-width: 575.98px) {
  .menu {
    height: auto;
    min-height: 40px;
  }
}
@media (max-width: 1100px) {
  .menu ul li {
    width: 15%;
    font-size: 75%;
  }
}
@media (max-width: 767.98px) {
  .menu ul li {
    font-size: 70%;
  }
}
@media (max-width: 575.98px) {
  .menu ul li {
    display: block;
    float: none;
    width: 100%;
    font-size: 60%;
  }
}

.images {
  position: relative;
}
.images::after {
  display: block;
  content: "";
  clear: both;
}
.images #slideshow {
  position: relative;
}
@media (max-width: 1380px) {
  .images #slideshow {
    width: 100% !important;
    height: auto !important;
  }
  .images #slideshow img {
    width: 100% !important;
    height: auto !important;
  }
}

table {
  width: 100%;
}

.full-wight {
  width: 100% !important;
}

.container-white,
.container-content {
  width: 100%;
  background-image: none;
}
.container-white::after,
.container-content::after {
  display: block;
  content: "";
  clear: both;
}
@media (min-width: 1380px) {
  .container-white,
  .container-content {
    width: 960px;
  }
}
.container-white .sidebar1,
.container-content .sidebar1 {
  width: 30%;
  background-color: #069;
}
@media (min-width: 1380px) {
  .container-white .sidebar1,
  .container-content .sidebar1 {
    width: 380px;
  }
}
@media (max-width: 575.98px) {
  .container-white .sidebar1,
  .container-content .sidebar1 {
    width: 100%;
    float: none;
    padding-bottom: 15px;
  }
}
@media (max-width: 767.98px) {
  .container-white .sidebar1 h1,
  .container-content .sidebar1 h1 {
    font-size: 120%;
    margin-top: 0;
  }
}
.container-white .content,
.container-content .content {
  width: 70%;
}
@media (min-width: 1380px) {
  .container-white .content,
  .container-content .content {
    width: 579px;
  }
}
@media (max-width: 575.98px) {
  .container-white .content,
  .container-content .content {
    width: 100%;
    float: none;
  }
}
.container-white .white_left,
.container-content .white_left {
  width: 60%;
}
.container-white .white_left table,
.container-content .white_left table {
  width: 100%;
}
@media (min-width: 1380px) {
  .container-white .white_left,
  .container-content .white_left {
    width: 659px;
  }
}
@media (max-width: 767.98px) {
  .container-white .white_left,
  .container-content .white_left {
    width: 100%;
    float: none;
    border-right: none;
  }
}
.container-white .white_right,
.container-content .white_right {
  width: 39%;
}
.container-white .white_right input,
.container-white .white_right textarea,
.container-content .white_right input,
.container-content .white_right textarea {
  width: 100% !important;
}
@media (min-width: 1380px) {
  .container-white .white_right,
  .container-content .white_right {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .container-white .white_right,
  .container-content .white_right {
    width: 100%;
    float: none;
  }
}

/*# sourceMappingURL=media.css.map */
